import { endOfDay } from 'date-fns'

export const getLastDateForYear = (year: number): Date => new Date(`12/31/${year}`)

export const getDefaultReferenceDate = (forYear: number): Date => {
  const isForCurrentYear = forYear === new Date().getFullYear()
  const lastDayOfTheYear = endOfDay(getLastDateForYear(forYear))

  if (isForCurrentYear) return new Date()

  return lastDayOfTheYear
}
