import React, { Fragment, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import ExpandableText from '../../../Components/ExpandableText'
import { Select } from '../../../Components/FormComponents/Select'
import { Icon } from '../../../Components/Icon'
import { Link } from '../../../Components/Link'
import { Message } from '../../../Components/Message'
import { PageContainer } from '../../../Components/PageContainer'
import { Table } from '../../../Components/Table'
import { IV2BudgetGroup } from '../../../Lib/graphql'
import { LayoutContext } from '../../../Providers/LayoutProvider'
import Colors from '../../../Theme/colors.module.scss'
import { EditBudgetGroupButton } from './Components/EditBudgetGroupButton'
import { ExportButton } from './Components/ExportButton'
import { FinalizeButton } from './Components/FinalizeButton'
import { PollingNotification } from './Components/PollingNotification'
import { ProgressInvitedEmployees } from './Components/ProgressInvitedEmployees'
import { ReferenceDate } from './Components/ReferenceDate'
import { getDefaultReferenceDate } from './Components/ReferenceDate/helpers'
import { generateYearArray, getTableConfig, makeBudgetGroupOptions, mapModalityEnumToIconName } from './helpers'
import { useBudgetGroups } from './Hooks/useBudgetGroups'
import { useWrpm } from './Hooks/useWrpm'
import { IBudgetGroupOption, ITransformedWRPMRow, IYearOptions } from './interfaces'
import Styles from './styles.module.scss'

const yearOptions = generateYearArray()
const desiredYear = Math.max(2024, new Date().getFullYear() - 1)
const defaultYear = yearOptions.find((option) => option.value === desiredYear) || yearOptions[0]

export const WRPMOverview: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const { organizationId } = useContext(LayoutContext) // FIXME: how to inform the user when there is no organization_id?
  const [selectedBudgetGroup, setSelectedBudgetGroup] = useState<IV2BudgetGroup | null>(null)
  const [selectedYear, setSelectedYear] = useState<IYearOptions>(defaultYear)
  const [selectedReferenceDate, setSelectedReferenceDate] = useState<Date>(getDefaultReferenceDate(defaultYear.value))
  const [showMessage, setShowMessage] = useState<boolean>(true)

  const highlightValue = (value: string, apply: boolean): React.ReactNode => {
    if (!apply) return value

    return <span className={Styles.highlight}>{value}</span>
  }

  const getKindCellContent = (item: ITransformedWRPMRow): ReactNode => {
    return (
      <div className={Styles.modalityKindWrapper}>
        {item.kind ? <Icon name={mapModalityEnumToIconName(item.kind)} /> : ''} {item.humanized}
      </div>
    )
  }

  const {
    previousBudgetGroupsData,
    isLoadingBudgetGroups,
    budgetGroupsForWrpm,
    fixedReferenceDate,
    statistics,
    hasOpenSurveys,
    isDefinitive,
  } = useBudgetGroups(`${organizationId}`, selectedYear.value, selectedReferenceDate)

  const budgetGroupOptions = useMemo((): IBudgetGroupOption[] => {
    if (!budgetGroupsForWrpm.length) return []

    return makeBudgetGroupOptions(budgetGroupsForWrpm)
  }, [budgetGroupsForWrpm])

  const selectedBudgetGroups = selectedBudgetGroup
    ? [selectedBudgetGroup.name!]
    : [...budgetGroupOptions.slice(1)].map(({ label }) => label)

  const { wrpmData, tableData } = useWrpm(
    `${organizationId}`,
    selectedYear.value,
    selectedBudgetGroups,
    selectedBudgetGroup,
    getKindCellContent,
    highlightValue
  )

  const handleYearChange = (year: IYearOptions): void => {
    setSelectedYear(year)
    setSelectedReferenceDate(getDefaultReferenceDate(year.value))
  }

  const handleReferenceDateChange = (value: Date): void => {
    setSelectedReferenceDate(value)
    setSelectedBudgetGroup(null)
  }

  useEffect(() => {
    if (budgetGroupOptions.length) {
      if (selectedBudgetGroup) {
        const budgetGroup = budgetGroupsForWrpm.find(({ name }) => name === selectedBudgetGroup.name)

        // if a budget group was selected before refetching budget groups this budget group should be selected again
        if (budgetGroup) setSelectedBudgetGroup(budgetGroup)
      } else setSelectedBudgetGroup(budgetGroupOptions[0].value)
    }
  }, [budgetGroupOptions, selectedBudgetGroup, budgetGroupsForWrpm])

  const showExportButton = isDefinitive
  const showEditBudgetGroupSourceButton = selectedBudgetGroup && !isDefinitive
  const showReferenceDate = previousBudgetGroupsData || !isLoadingBudgetGroups
  const wrpmMessage = (
    <Trans
      i18nKey="In the context of the <portalLink>{{ linkText }}</portalLink> it is mandatory to provide a report of the kilometers traveled by your employees. More information can be found on the <websiteLink>{{ websiteText }}</websiteLink>."
      values={{
        linkText: t('reporting obligation for work-related personal mobility'),
        websiteText: t('website of the RVO'),
      }}
      components={{
        portalLink: (
          <Link
            href="https://www.rvo.nl/onderwerpen/rapportage-wpm"
            ariaLabel={t('reporting obligation for work-related personal mobility')}
            style={{ color: Colors.blue500 }}
          />
        ),
        websiteLink: (
          <Link
            href="https://www.rvo.nl/onderwerpen/rapportage-wpm"
            ariaLabel={t('website of the RVO')}
            style={{ color: Colors.blue500 }}
          />
        ),
      }}
    />
  )

  return (
    <PageContainer
      header={{
        title: t('Passenger mobility legislation'),
        showLine: true,
      }}
    >
      <Fragment>
        {showMessage && (
          <Message
            variant="warning"
            description={<ExpandableText text={wrpmMessage} />}
            descriptionElement="div"
            className={Styles.message}
            iconProps={{
              icon: 'cross',
              onClick: (): void => setShowMessage(!showMessage),
            }}
          />
        )}

        <div className={Styles.periodSelection}>
          <div className={Styles.yearSelectWrapper}>
            <Select
              id="data-year"
              ariaLabel={t('Year')}
              value={selectedYear}
              options={generateYearArray()}
              onChange={handleYearChange}
            />
          </div>

          {showReferenceDate && (
            <ReferenceDate
              forYear={selectedYear.value}
              selectedDate={selectedReferenceDate}
              fixedDate={fixedReferenceDate}
              onChange={handleReferenceDateChange}
            />
          )}
        </div>

        <ProgressInvitedEmployees
          referenceDate={selectedReferenceDate}
          statistics={statistics}
          isDataDefinitive={isDefinitive}
          hasOpenSurveys={hasOpenSurveys}
          budgetGroups={budgetGroupsForWrpm}
          forYear={selectedYear.value}
        />

        <div className={Styles.controlsContainer}>
          <div className={Styles.budgetGroupControls}>
            <div className={Styles.budgetGroupWrapper}>
              <Select
                id="budgetgroup"
                ariaLabel={t('Budgetgroup')}
                value={budgetGroupOptions.find(({ value }) => value === selectedBudgetGroup)}
                options={budgetGroupOptions}
                onChange={(option: IBudgetGroupOption): void => setSelectedBudgetGroup(option.value)}
              />
            </div>

            {showEditBudgetGroupSourceButton && (
              <EditBudgetGroupButton
                organizationId={`${organizationId}`}
                year={selectedYear.value}
                budgetGroup={selectedBudgetGroup}
                wrpmData={wrpmData?.reisbalansV2.wrpm || []}
              />
            )}
          </div>

          {!showExportButton && (
            <FinalizeButton
              organizationId={`${organizationId}`}
              year={selectedYear.value}
              budgetGroups={budgetGroupsForWrpm}
            />
          )}

          {showExportButton && <ExportButton organizationId={`${organizationId}`} forYear={selectedYear.value} />}
        </div>

        {wrpmData?.reisbalansV2?.wrpm ? (
          <Table tableConfig={getTableConfig()} tableData={tableData} />
        ) : (
          <PollingNotification year={selectedYear.value} />
        )}
      </Fragment>
    </PageContainer>
  )
}
