import { useMemo } from 'react'

import { useTranslationDecorator } from '../../../../Hooks/useTranslationDecorator'
import { formatDateString, parseDate } from '../../../../Lib/Formatters/DateFormatter'
import {
  IGetBudgetGroupsQuery,
  IV2BudgetGroup,
  IWrpmSurveyStatusEnum,
  useGetBudgetGroupsQuery,
} from '../../../../Lib/graphql'
import { calculateSample } from '../helpers'
import { IStatistics, TRespondentsAndInvitees } from '../interfaces'

export const useBudgetGroups = (
  organizationId: string,
  forYear: number,
  referenceDate: Date
): {
  budgetGroupsData: IGetBudgetGroupsQuery | undefined
  previousBudgetGroupsData: IGetBudgetGroupsQuery | undefined
  isLoadingBudgetGroups: boolean
  budgetGroupsForWrpm: IV2BudgetGroup[]
  fixedReferenceDate: Date | null
  statistics: IStatistics
  hasOpenSurveys: boolean
  isDefinitive: boolean
} => {
  const { locale } = useTranslationDecorator()

  const {
    data: budgetGroupsData,
    previousData: previousBudgetGroupsData,
    loading: isLoadingBudgetGroups,
  } = useGetBudgetGroupsQuery({
    variables: {
      organizationId,
      forYear,
      referenceDate: formatDateString(referenceDate, 'dd-MM-yyyy', locale),
    },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    skip: !organizationId,
  })

  const budgetGroupsForWrpm = useMemo(() => {
    return budgetGroupsData?.reisbalansV2?.v2Organization.budgetGroupsForWrpm || []
  }, [budgetGroupsData?.reisbalansV2?.v2Organization.budgetGroupsForWrpm])

  const hasOpenSurveys = useMemo(() => {
    return budgetGroupsForWrpm.some(
      ({ wrpmSurvey }) => wrpmSurvey?.status === IWrpmSurveyStatusEnum.OPEN && !!wrpmSurvey?.inviteSentAt
    )
  }, [budgetGroupsForWrpm])

  const isDefinitive = useMemo(() => {
    return budgetGroupsForWrpm.every(({ wrpmSurvey }) => wrpmSurvey?.status === IWrpmSurveyStatusEnum.DEFINITIVE)
  }, [budgetGroupsForWrpm])

  const fixedReferenceDate = useMemo(() => {
    const isForCurrentYear = forYear === new Date().getFullYear()

    if (isForCurrentYear) return new Date()

    const budgetGroupWithReferenceDate = budgetGroupsForWrpm.find(
      (budgetGroup) => !!budgetGroup?.wrpmSurvey?.referenceDate
    )

    if (!budgetGroupWithReferenceDate?.wrpmSurvey?.referenceDate) return null

    return parseDate(budgetGroupWithReferenceDate.wrpmSurvey.referenceDate)
  }, [budgetGroupsForWrpm, forYear])

  const invitedBudgetGroups = useMemo(() => {
    return budgetGroupsForWrpm.filter(({ wrpmSurvey }) => !!wrpmSurvey?.inviteSentAt)
  }, [budgetGroupsForWrpm])

  const lastInvitationDate = useMemo((): Date | null => {
    if (!invitedBudgetGroups.length) return null

    invitedBudgetGroups.sort(
      // eslint-disable-next-line @getify/proper-arrows/params
      (a, b) => new Date(b.wrpmSurvey!.inviteSentAt!).getTime() - new Date(a.wrpmSurvey!.inviteSentAt!).getTime()
    )

    return parseDate(invitedBudgetGroups[0].wrpmSurvey!.inviteSentAt!)
  }, [invitedBudgetGroups])

  const lastReminderDate = useMemo((): Date | null => {
    if (!invitedBudgetGroups.length) return null

    invitedBudgetGroups.sort(
      // eslint-disable-next-line @getify/proper-arrows/params
      (a, b) =>
        new Date(b.wrpmSurvey!.lastReminderSentAt!).getTime() - new Date(a.wrpmSurvey!.lastReminderSentAt!).getTime()
    )

    if (!invitedBudgetGroups[0].wrpmSurvey?.lastReminderSentAt) return null

    return parseDate(invitedBudgetGroups[0].wrpmSurvey?.lastReminderSentAt)
  }, [invitedBudgetGroups])

  const statistics = useMemo((): IStatistics => {
    if (!invitedBudgetGroups.length) {
      return {
        lastInvitationDate: null,
        lastReminderDate: null,
        numRespondents: null,
        numInvitees: null,
        thresholdMet: null,
      }
    }

    const respondentsAndInvitees = invitedBudgetGroups.reduce(
      (acc: TRespondentsAndInvitees, budgetGroup) => {
        acc.respondents += budgetGroup.wrpmSurvey!.respondents!
        acc.invitees += budgetGroup.wrpmSurvey!.invitees!

        return acc
      },
      { respondents: 0, invitees: 0 }
    )

    const sample = calculateSample(respondentsAndInvitees.invitees)

    return {
      lastInvitationDate,
      lastReminderDate,
      numRespondents: respondentsAndInvitees.respondents,
      numInvitees: respondentsAndInvitees.invitees,
      thresholdMet: respondentsAndInvitees.respondents >= sample,
    }
  }, [invitedBudgetGroups, lastInvitationDate, lastReminderDate])

  return {
    budgetGroupsData,
    previousBudgetGroupsData,
    isLoadingBudgetGroups,
    budgetGroupsForWrpm,
    fixedReferenceDate,
    statistics,
    hasOpenSurveys,
    isDefinitive,
  }
}
