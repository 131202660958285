import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  IGetWrpmQuery,
  IV2BudgetGroup,
  IWrpmSourceEnum,
  IWrpmSurveySourceEnum,
  useGetWrpmLazyQuery,
  useGetWrpmQuery,
} from '../../../../Lib/graphql'
import { ISourceDiff, ITransformedWRPMRow, IWRPMTableRow } from '../interfaces'

export const useWrpm = (
  organizationId: string,
  forYear: number,
  budgetGroups: string[],
  selectedBudgetGroup: IV2BudgetGroup | null,
  getKindCellContent: (item: ITransformedWRPMRow) => ReactNode,
  highlightValue: (value: string, apply: boolean) => ReactNode
): {
  wrpmData: IGetWrpmQuery | undefined
  tableData: IWRPMTableRow[]
} => {
  const disableHighlightFeature = true
  const { t } = useTranslation()
  const [sourceDiff, setSourceDiff] = useState<ISourceDiff[]>([])
  const [getWrpm] = useGetWrpmLazyQuery()
  const {
    data: wrpmData,
    startPolling,
    stopPolling,
  } = useGetWrpmQuery({
    variables: {
      organizationId,
      forYear,
      budgetGroupNames: budgetGroups,
    },
    skip: !organizationId || !budgetGroups.length,
  })

  const renderWithUnit = (value: number, unit: string): string => `${value.toLocaleString('nl')} ${unit}`

  const createTableData = (transformedWRPMData: ITransformedWRPMRow[]): IWRPMTableRow[] => {
    return transformedWRPMData.map((item): IWRPMTableRow => {
      const result = sourceDiff.find(({ modality }) => modality === item.id)
      const highlightCommute = !!result?.updatedValues?.includes('commute')
      const highlightBusiness = !!result?.updatedValues?.includes('business')

      return {
        id: item.id,
        kind: getKindCellContent(item),
        commute_kms: highlightValue(renderWithUnit(item.commute_kms, t('km')), highlightCommute),
        commute_co2: renderWithUnit(item.commute_co2, t('gram')),
        business_kms: highlightValue(renderWithUnit(item.business_kms, t('km')), highlightBusiness),
        business_co2: renderWithUnit(item.business_co2, t('gram')),
      }
    })
  }

  const transformWRPMData = (WRPMData: IGetWrpmQuery): ITransformedWRPMRow[] => {
    const { wrpm } = WRPMData.reisbalansV2

    if (!wrpm?.length) return []

    const transformedWRPMData = wrpm.map((item): ITransformedWRPMRow => {
      return {
        id: item?.modality?.value || '',
        kind: item?.modality?.value || null,
        humanized: item?.modality?.humanized || '',
        commute_kms: parseInt(item?.statsPerModalityCommute?.totalKms, 10) || 0,
        commute_co2: parseInt(item?.statsPerModalityCommute?.totalCo2, 10) || 0,
        business_kms: parseInt(item?.statsPerModalityBusiness?.totalKms, 10) || 0,
        business_co2: parseInt(item?.statsPerModalityBusiness?.totalCo2, 10) || 0,
      }
    })

    const cumulativeRow = transformedWRPMData.reduce(
      (acc, value): ITransformedWRPMRow => {
        acc.commute_kms += value.commute_kms
        acc.commute_co2 += value.commute_co2
        acc.business_kms += value.business_kms
        acc.business_co2 += value.business_co2

        return acc
      },
      {
        id: 'total',
        kind: null,
        humanized: t('Total'),
        commute_kms: 0,
        commute_co2: 0,
        business_kms: 0,
        business_co2: 0,
      }
    )

    return [cumulativeRow, ...transformedWRPMData]
  }

  const getDataDiff = useCallback(
    (
      rbData: IGetWrpmQuery['reisbalansV2']['wrpm'],
      manualData: IGetWrpmQuery['reisbalansV2']['wrpm']
    ): ISourceDiff[] => {
      if (!rbData) return []

      return rbData.reduce((acc, { modality, statsPerModalityCommute, statsPerModalityBusiness }) => {
        const manualDataItem = manualData?.find((item) => modality?.value === item.modality?.value)

        if (manualDataItem) {
          const updatedValues: ISourceDiff['updatedValues'] = []

          if (statsPerModalityCommute?.totalKms !== manualDataItem.statsPerModalityCommute?.totalKms)
            updatedValues.push('commute')

          if (statsPerModalityBusiness?.totalKms !== manualDataItem.statsPerModalityBusiness?.totalKms)
            updatedValues.push('business')

          acc.push({
            modality: modality?.value || '',
            updatedValues,
          })
        }

        return acc
      }, [] as ISourceDiff[])
    },
    []
  )

  const tableData = useMemo(() => {
    if (!wrpmData) return []

    return createTableData(transformWRPMData(wrpmData))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrpmData, sourceDiff])

  useEffect(() => {
    // NOTE temporary disabled due to backend issue RBC-2387
    if (disableHighlightFeature) return

    if (!wrpmData?.reisbalansV2.wrpm) return

    if (!selectedBudgetGroup) return

    const { wrpmSurvey } = selectedBudgetGroup

    if (!wrpmSurvey?.source || wrpmSurvey?.source !== IWrpmSurveySourceEnum.MANUAL) return

    getWrpm({
      fetchPolicy: 'no-cache',
      variables: {
        organizationId,
        budgetGroupNames: [selectedBudgetGroup.name!],
        sourceOverride: IWrpmSourceEnum.REISBALANS,
        forYear,
      },
    })
      .then((response) => {
        setSourceDiff(getDataDiff(response.data?.reisbalansV2.wrpm, wrpmData?.reisbalansV2.wrpm))
      })
      .catch(() => {})
  }, [
    disableHighlightFeature,
    wrpmData?.reisbalansV2.wrpm,
    selectedBudgetGroup,
    organizationId,
    forYear,
    getWrpm,
    getDataDiff,
  ])

  useEffect(() => {
    startPolling(5000)

    if (wrpmData?.reisbalansV2?.wrpm) stopPolling()

    return (): void => {
      stopPolling()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrpmData])

  return {
    wrpmData,
    tableData,
  }
}
